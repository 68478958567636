@import "/ThemeVariables.less";

.quicksight-dashboard-container {
  border-radius: 0.5rem;
  background: @citrine-white-color;
  box-shadow: 0px 0px 0.635rem @shadow-color;
  margin: 0 0.75rem;
}

.qs-dashboard-card {
  border: 1px dashed @black-color !important;
  padding: 0 1rem !important;
  height: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 0.675rem;
    padding: 1rem 0.5rem;
    height: 100%;
    

    .qs-tag {
      background: @vanilla-color;
      border: 1px solid @primary-color;
      border-radius: 0.5rem;
      padding: 0.375rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
    }

    .qs-icon,.qs-author,.qs-date{
      font-weight: 600;
      color: @black-60-color;
    }

    .tag-row{
      margin: auto 0 0 0;
    }
  }
}

.enable-dashboard-card {
  cursor: pointer;
}

.disable-qs-dashboard-card {
  background: @foggy-down-color !important;
  cursor: not-allowed !important;
  .qs-text-color{
    color: @shadow-steel-color !important;
  }
  .qs-tag{
    background-color: @misty-meadow-color !important;
    border-color: @misty-meadow-color !important;
  }
}

.qs-text-color {
  color: @midnight-forest-color !important;
  font-weight: 700;
}

.iframeBox .quicksight-embedding-iframe {
  min-height: 94vh;
  width: 100%;
}

.ai-insight-drawer { 
 .ant-drawer-close {
    margin: 0;
    color: @black-color;
  }

  .ant-drawer-header {
    padding-right: 0.5rem;
  }

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: hidden;
  }

  .ant-drawer-title {
    font-weight: 700;
  }
}

.quicksight-dashboard-view-container {
  background: @white-color;
  border-radius: 0.5rem;
  height: 85vh;
  min-height: 85vh;
  position: relative;
}

.quicksight-loader {
  padding: 20;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem;
}

.dashboard-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-close {
      margin: 0;
    }
  }

  .dashboard-selector {
    .ant-select-selector {
      width: 100%;
    }
  }

  .dashboard-custom-footer {
    position: absolute;
    width: 90%;
    margin-bottom: 1rem;
    bottom: 0;
  }
}

.presciptive-logo {
  height: 1.5rem;
  width: 1.5rem;
}

.dashboard-more-outlined {
  margin: 0 -0.5rem 0 0;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;