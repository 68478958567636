@import "/ThemeVariables.less";

.tree-container {
  background: none;
}
.tree-container .ant-tree-switcher {
  margin-top: 1rem;
}

.tree-container .ant-tree-treenode,
.tree-container .ant-tree-node-content-wrapper,
.tree-container .ant-tree .ant-tree-treenode {
  width: 100%;
}

.tree-container .ant-tree-title {
  font-size: 1rem;
}

.tree-container .sub-source-title {
  margin-left: 2rem;
}

.title-wrapper {
  display: flex;
  width: 100%;
}
.syncBox {
  width: 100%;
}

.tree-container .ant-tree-node-content-wrapper:hover {
  background: none !important;
}

.datasource-container-col-1 {
  display: flex;
  width: 35%;
  padding: 0.875rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.datasource-container-col-2 {
  display: flex;
  width: 65%;
  justify-content: space-between;
  padding: 0.875rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  align-items: center;
}

.datasource-wrapper-col-1 {
  display: flex;
  flex-direction: column;
  gap: 0.175rem;
  margin-left: 1rem;
  justify-content: center;
}

.italic {
  font-style: italic;
}

.ant-form-item-explain.ant-form-item-explain-connected {
  height: 16px;
}

.data-source-panel .slide___3-Nqo {
  min-height: 210px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

//**************************************************************************************
//********************** Request Access Card Styles Start ***************************//
//**************************************************************************************

.selection-logo {
  margin: 3.5rem 0 1.875rem 0;
}

.account-setup-card {
  height: 100%;
}

.request-access-card {
  background: @off-catskill-white-color;
}
.start-company-card {
  background: @mine-shaft-color;
}

.start-company-card,
.request-access-card {
  padding: 1.25rem;
  border-radius: 1.25rem;
  .ant-card {
    background: none;
    height: 100%;
    .ant-card-body {
      height: 100%;
    }
  }
}

.request-access-img {
  max-height: 190px;
}

.account-setup-btn {
  border: none;
  font-family: "Matter-bold";
  padding: 0.5rem;
  display: block;
  align-self: flex-end;
}

.account-setup-text {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.height-44 {
  height: 44px;
}

//**************************************************************************************
//************************ Request Access Card Styles End *****************************//
//**************************************************************************************

//**************************************************************************************
//************************** Login Module Style Start ********************************//
//**************************************************************************************
.login-module-col-2 {
  height: 100vh;
  width: 100%;
  margin: 0 !important;
}

.login-module-logo {
  .ant-image {
    display: flex;
    .ant-image-img {
      margin: 0 auto 3rem;
      height: 6.5rem;
    }
  }
}

// .login-module-input {
//   border-radius: 0.5rem;
//   height: 2.75rem;
// }

.confirm-email-paragraph {
  color: @light-dove-gray-color;
}

//**************************************************************************************
//*************************** Login Module Style End *********************************//
//**************************************************************************************

.request-image-container {
  width: 100%;
  margin: 0.675rem 0;
}

.request-image {
  width: 100%;
  max-height: 275px;
}

.pending-approval-text {
  text-align: center;
  font-size: 1.875rem;
  margin: 0.675rem 0 0 0;
}

.request-send-text {
  font-size: 1.125rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 462px;
  margin: auto;
}

.connect-mail-text {
  margin: 0.75rem 0 1.25rem 0;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;