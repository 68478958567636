@import "/ThemeVariables.less";

.billing-page-container {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0;
}

.close-icon {
  font-size: 1.25rem !important;
  position: absolute !important;
  top: -0.5rem;
  right: -0.5rem;
  background: @primary-color !important;
  border-color: @primary-color !important;
  color: @white-color;
  border-radius: 50% !important;
  z-index: 999;
  transition: all 0.3s ease;
}

.close-icon:hover {
  background: @primary-color;
  border-color: @primary-color;
  color: @white-color;
}

.green-circle {
  background: @green-circle-color;
  color: @green-circle-color !important;
}
.red-circle {
  background: @red-circle-color;
  color: @red-circle-color !important;
}
.yellow-circle {
  background: @primary-color !important ;
  color: @primary-color !important;
}
.grey-circle {
  background: @light-dove-gray-color;
  color: @light-dove-gray-color !important;
}

.main-bg {
  background: @off-catskill-white-color;
}

.connector-card {
  background: @white-color;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 170px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;

  .ant-card-body {
    padding-bottom: 0;

    .required-configuration-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: @primary-color;
    }
  }
}

// .connector-card img {
//   border-radius: 50%;
// }

.connector-card .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.connector-card button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

.connector-btn {
  color: @black-color;
  font-size: 1rem;
  height: 2rem;
  background: none;
  margin-top: 0.5rem;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  text-align: center;
  margin-top: 1rem;
  line-height: 1;
  font-family: "Matter-bold";
  font-weight: 500;
}

.connector-btn:focus,
.connector-btn:hover {
  color: #000000;
  border: none;
  background: none;
}

.check-outline {
  color: @primary-color !important;
  border-color: @primary-color !important;
  border-radius: 50% !important;
  height: 3.125rem !important;
  width: 3.125rem !important;
}

.disabled-card {
  pointer-events: none;
  background: @wild-sand-color !important;
  box-shadow: none;
}

.ant-click-animating-node {
  display: none;
}

.carousel-btn {
  background: none;
  border: none;
}

.carousel-btn-icon {
  border-radius: 50%;
  font-size: 8px;
  color: #000000;
  border: 1px solid #000000;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-btn-icon:hover {
  background-color: #ffc90a;
  color: #ffffff;
  border-color: #ffc90a;
}

.carousel-btn:disabled {
  opacity: 0.5;
}

.carousel-btn-container {
  display: flex;
  justify-content: flex-end;
}

.horizontalSlider___281Ls {
  margin-bottom: 0.5rem;
}

.counter-button {
  color: @black-color;
  background: none;
  border: 1px solid @black-color;
  border-radius: 0px;
}

.counter-button:disabled {
  border-color: @black-color;
  color: @black-color;
  background: @white-color;
}

.counter-button:hover {
  color: @primary-color;
}

.counter-button:focus,
.counter-button:hover {
  color: @primary-color;
  border: 1px solid @black-color;
  background: none;
}
.btn-minus {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.btn-plus {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.counter-text {
  font-size: 1rem;
  font-weight: 900;
  color: @black-color;
  padding: 0.25rem 0.5rem 0.375rem;
  width: 3.125rem;
  border-top: 1px solid @black-color;
  border-bottom: 1px solid @black-color;
}

.billing-page-container .ant-skeleton-element {
  min-width: 100% !important;
}

.circle-icon {
  margin-right: 0.25rem;
  font-weight: 900;
  font-size: 8px;
  border-radius: 50%;
}

.billing-counter span.ant-typography.counter-text {
  height: 2rem !important;
  display: inline-block !important;
  transform: translateY(1.5px) !important;
  width: 2.5rem !important;
  text-align: center !important;
}

// .btn-disable:disabled {
//   background-color: @cararra-color !important;
//   border-color: @cararra-color !important;
// }
.billing-table-container {
  background: @white-color;
  border-radius: 0.5rem;
  padding: 0.675rem;

  .header-text {
    font-size: 1.25rem;
    color: @black-color;
    display: flex;
    padding: 0 0 1rem 2rem;
    font-family: "Matter-bold";
    margin: 2rem 0;
    border-bottom: 1px solid @botticelli-color;
  }

  .billing-table-wrapper {
    font-family: "Matter";

    .ant-table-thead {
      .ant-table-cell {
        text-transform: uppercase;
        font-weight: 500;
        font-family: "Matter-bold";
        font-size: 1.25rem;
        background: none;
        border: 0 !important ;
      }
    }

    .table-row-container {
      text-align: center;
    }

    .col-header {
      color: @bunker-color;
      font-size: 1rem;
    }

    .bg-light-blue {
      background-color: @off-catskill-white-color;
      font-weight: 600;
      font-family: "Matter-bold";
      font-size: 1rem;
    }

    .plan-yearly {
      color: @primary-color;
      font-size: 1.125rem;
    }

    tr td:nth-last-child(1) {
      border-right: 0 !important;
    }

    tr:nth-last-child(1) td {
      border-bottom: 0 !important;
    }

    tr td:nth-child(1) {
      border-right: 2px solid @off-catskill-white-color !important;
    }

    .ant-table.ant-table-bordered > .ant-table-container {
      border-left: 0px !important;

      .ant-table-content > table {
        border-top: 0px !important;
        > tbody > tr > td {
          border-right: 2px solid @off-catskill-white-color;
        }
      }
    }

    .ant-table-tbody > tr > td {
      border-bottom: 2px solid @off-catskill-white-color;
    }
  }
}

.modal-radio-btn span {
  font-size: 1.125rem;
  font-weight: 600;
}

.modal-check-mark-icon {
  color: @primary-color;
  font-size: 4.5rem;
}

.popular-container {
  background-color: @black-color;
  color: @white-color;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  width: 7.25rem;
  height: 3.625rem;
  padding-top: 1.5rem;
  transform: rotate(45deg) translateX(1.25rem) translateY(-2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.table-row-container {
  overflow: hidden;
  text-align: center;
}

.schoolbi-plan-heading {
  margin-bottom: 0 !important;
  font-weight: 600;
  font-size: 2rem !important;
  font-family: "Matter-bold";
}

.schoolbi-plan-subheading {
  font-weight: 400;
  font-size: 1.125rem;
}

.plan-details-header {
  margin: 0;
  font-family: "Matter-bold";
  border-top: 1px solid @botticelli-color;
  border-bottom: 1px solid @botticelli-color;
  width: 100%;
  padding: 1rem;
}

.row-one-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 225px;

  .plan-details-title {
    text-transform: uppercase;
    color: @off-mine-shaft-color;
    margin: 0;
    font-family: "Matter-bold";
  }

  .plan-desc {
    color: @primary-color;
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: bold;
  }

  .need-more-container {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .extra-charge-container {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.plan-counter-background {
  background: url("./../../assets/images/Billing/PlanCounterContainer.svg");
  background-repeat: no-repeat;
  background-position: left center;
  height: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  padding-left: 0.75rem;
}

.currentplaninfo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 31.8%;
  cursor: pointer;
}

.currentplan-name {
  color: @off-mine-shaft-color;
  font-weight: 700;
  font-size: 16;
  text-transform: uppercase;
}

.currentplan-target {
  color: @light-dove-gray-color;
  line-height: 1rem;
  width: 90%;
}

.allplan-container {
  border-right: 1px solid @heather-color;
}

.current-plan-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @citrine-white;
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  gap: 1rem;
  text-transform: uppercase;
  font-family: "Matter-bold";

  h4 {
    margin: 0 !important;
  }
}

.assign-btn {
  color: @white-color !important;
  background-color: @black-color !important ;
}

.admin-assign-plan {
  background: #f1f5f9;
  border: 0.5px solid #eef2f6;
  border-radius: 0.5rem;
  padding: 1rem;

  .ant-checkbox-wrapper {
    span {
      font-size: 1rem;
      color: #0c1515;
    }
  }
}

.billing-slider {
  .billing-slider-formatter {
    padding: 0 1rem;
  }
}

.selected-plan-container {
  background-color: @white-color;
  filter: drop-shadow(0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1));
  border-radius: 0.5rem;
}

.currentplaninfo-container {
  padding: 0.5rem;
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.635rem;
}

.divider-col {
  width: 31.8%;
}

.divider-container {
  border: 1px solid @heather-color;
  height: 0.625rem;
  border-bottom: none;
}

.allplan-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.625rem;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;