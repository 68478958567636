@import "/ThemeVariables.less";

.dashboard-bg {
  border-radius: 0.375rem;
  padding: 1rem;
  background-color: @white-color;
}

.hero-section-helper {
  .dashboard-bg();
}

.ant-selector-helper {
  font-family: "Matter-bold";
  border-radius: 0.25rem !important;
  box-shadow: none;
  .ant-select-selection-item {
    padding: 0;
  }
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .chart-title {
    margin: 0;
    font-family: "Matter-bold";
  }

  .refresh-recurring-nosheet-title {
    color: @bunker-color !important;
    opacity: 0.4;
  }

  .refresh-recurring-nosheet-text {
    color: @light-dove-gray-color;
    opacity: 0.4;
    line-height: 1.2;
  }

  .ant-segmented-item-selected {
    outline: 1px solid @primary-color;
  }

  .dashboard-icon-container {
    border: 1px solid rgba(137, 137, 137, 0.6);
    border-radius: 50%;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  }

  .title {
    margin: 0;
    line-height: 1.8;
    font-family: "Matter-bold";
  }

  .hero-section {
    .hero-section-helper();

    .dashboard-text {
      color: @bunker-color;
      font-family: "Matter-bold";
      margin: 0;
      font-size: 1.25rem;
    }

    .greetings-text {
      color: @bunker-color;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .greetings-subtext {
      color: @light-dove-gray-color;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .blackbaud-recurring-refresh-chart {
    .dashboard-bg();
    gap: 1rem;
    padding: 1rem 0 1rem 1rem;

    .chart-title-container {
      display: flex;
      align-items: center;

      .dashboard-title-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        .ant-select-selector {
          .ant-selector-helper();
        }
      }

      .data-provider-container {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.625rem;
        // background-image: url("../../assets/images/Dashboards/BeeBG.svg");
        // background-repeat: no-repeat;

        // .ant-select-selector {
        //   .ant-selector-helper();
        //   border: none;
        //   color: @white-color;
        //   background-color: @black-color;

        //   .ant-select-selection-item {
        //     padding: 0;
        //     display: flex;
        //     align-items: center;
        //     gap: 0.25rem;

        //     &::before {
        //       content: "\25CF";
        //       color: @green-circle-color;
        //     }
        //   }
        // }
      }
    }
  }

  .recurring-refresh-container {
    display: flex;
    gap: 0.25rem;

    // .refresh-interval-time-select {
    //   display: flex;
    //   align-items: center;
    //   .ant-select-selector {
    //     // border: none;
    //     color: @primary-color;
    //     font-family: "Matter-bold";
    //     box-shadow: none !important;
    //   }
    // }
    .refresh-interval-time-select,
    .historic-time-select,
    .sheet-select {
      .ant-select-selector {
        .ant-selector-helper();
      }
    }
  }

  .quota-limit-container {
    display: flex;
    gap: 0.5rem;

    // .ant-segmented-item-selected {
    //   color: @primary-color;
    //   background-color: @black-color;
    // }
  }

  .api-calls-chart-container {
    display: flex;
    gap: 0.5rem;
  }
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;