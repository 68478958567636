@import "/ThemeVariables.less";

.manage-files-container {
  background-color: @off-catskill-white-color;
  padding: 1rem;
  height: 100%;
  border-radius: 0.5rem;
  filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.08));

  .manage-files-heading {
    color: @outer-space-color;
    width: 100%;
    padding: 0 0 0.5rem 0;
    // border-bottom: 1px solid @botticelli-color;
    display: flex;
    justify-content: space-between;

    .archieve-btn {
      background-color: transparent !important;
      border: 1px solid @primary-color !important;
      color: @primary-color !important;
      text-shadow: none !important;
    }
  }

  .file-upload-dragger {
    // cursor: not-allowed;
    // pointer-events: all;
    // > * {
    //   cursor: not-allowed;
    //   pointer-events: all;
    // }
    border-color: @black-color !important;
    background-color: @white-color !important;
    width: 100%;
    border-radius: 0.5rem;
    .ant-upload-list {
      display: none !important;
    }
  }

  .upload-link-container {
    display: flex !important;
    border: 1px solid @black-color;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .sheet-upload-btn{
      color: @white-color !important ;
      border: 1px solid @black-color !important;
      border-radius: .5rem;
      background-color: @black-color !important;
    }

    .ant-input-group-addon,
    .ant-input {
      background-color: transparent;
      border: none;
      height: 2.5rem;
      padding-left: 0;
    }

    .ant-input {
      font-size: 1.125rem;
      font-weight: 600;
      box-shadow: none;
    }
  }

  .upload-file-container {
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem !important;
    background: @white-color;
    display: flex;
    gap: 1rem;

    .upload-progress-container {
      gap: 8rem;
    }

    .ant-slider-rail {
      background-color: @silver-color;
    }
  }
}

.refresh-interval-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-form-item-label {
    padding: 0;
  }

  .refresh-interval-radio-btn {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-radio-wrapper {
      width: 100%;
    }

    .ant-radio-button-wrapper {
      border: none !important;
      font-size: 1.125rem;
      border-bottom: 1px solid @greyser-color !important;
      color: @outer-space-color;
      height: 3rem;
      display: flex;
      align-items: center;
      min-width: 400px;
    }
    .ant-radio-button-wrapper:hover {
      background-color: @off-catskill-white-color;
    }
    .ant-radio-button-wrapper:focus-within {
      box-shadow: none;
    }
    .ant-radio-button-wrapper:focus {
      background-color: @greyser-color;
    }
    .ant-radio-button-checked {
      background-color: @greyser-color;
    }
    .ant-radio-button-wrapper::before {
      display: none;
    }
  }
  .repeat-number-style {
    background-color: @wild-sand-color;
    border: none;
    box-shadow: none;
    width: 4rem;
    border-bottom: 3px solid @primary-color !important ;
    // color: @outer-space-color;
  }
  .repeat-day-style {
    .ant-select-selector {
      border: none;
      background-color: @wild-sand-color;
      border-radius: 0 !important;
      color: @black-color;
    }
  }
  .repeat-date-picker {
    background-color: @wild-sand-color;
    color: @silver-text-color;
    border: none;

    .input:focus {
      border-color: none;
    }
    input::placeholder {
      // color: @black-color;
      color: @silver-text-color;
    }
    .ant-picker-clear,
    .ant-picker-suffix {
      display: none;
    }
  }

  .repeat-occurrences {
    background-color: @wild-sand-color;
    border: none;
    box-shadow: none;
    .ant-input-number-group-addon {
      background-color: @wild-sand-color;
      color: @silver-text-color;
      border: none;
    }
  }

  .weekend-checkbox {
    .ant-checkbox-group {
      gap: 0.5rem;
      display: flex;
    }
    .ant-checkbox-wrapper {
      margin: 0;
    }

    .ant-checkbox-inner {
      border-radius: 50%;
      border-color: @wild-sand-color;
      height: 1.5rem;
      width: 1.5rem;
      background-color: @wild-sand-color;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @primary-color;
    }
    .ant-checkbox-checked::after {
      border-radius: 50%;
      border-color: @primary-color;
    }
    .saturday .ant-checkbox-inner::before,
    .sunday .ant-checkbox-inner::before {
      content: "S";
    }

    .monday .ant-checkbox-inner::before {
      content: "M";
    }

    .thursday .ant-checkbox-inner::before,
    .tuesday .ant-checkbox-inner::before {
      content: "T";
    }

    .wednesday .ant-checkbox-inner::before {
      content: "W";
    }

    .friday .ant-checkbox-inner::before {
      content: "F";
    }

    .ant-checkbox-inner::before,
    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 0;
      border: none;
      transform: none;
      font-size: 0.875rem;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.file-history-table {
  .ant-table-thead {
    .ant-table-cell::before {
      display: none;
    }
    > tr > th {
      background-color: @white-color;
      font-weight: bold;
      font-size: 1.125rem;
      border-bottom: 1px solid @botticelli-color;
      vertical-align: top;
      line-height: 1;
    }
  }

  .ant-table-tbody {
    > tr > td {
      font-size: 1rem;
      border: none;
      height: fit-content !important;
      vertical-align: top;
      line-height: 1.25rem;
      border-bottom: 1px solid @light-mercury-color;
    }
    > tr:nth-last-child(1) > td {
      border: none;
    }

    .ant-table-expanded-row {
      background-color: @light-zircon-color;
    }

    .ant-table-row-expand-icon {
      border-radius: 50%;
      transform: scale(1.5);
      background: @light-dove-gray-color;
      border-color: @light-dove-gray-color;
      color: @white-color;
    }
    .worksheet-container {
      cursor: pointer;
      color: @black-color;
      font-weight: bold;
      width: fit-content;
      padding: 0 1.5rem;
      &:hover {
        background-color: @black-color !important ;
        color: @white-color;
      }
    }

    .ant-table-row-expand-icon-expanded {
      position: relative;
      &::after {
        content: "";
        width: 30px;
        height: 45px;
        background-color: transparent;
        z-index: 99;
        background-image: url("/assets/images/ManageFiles/RecentFilesSheets.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 16px;
        left: 6px;
        bottom: unset;
        transform: unset;
      }
    }

    .ant-table-expanded-row {
      td {
        padding-left: 65px;
        background: none;
      }
    }
  }
}

.file-preview-table-container {
  background-color: @white-color;
  // border: none;
  border-radius: 0.5rem;
  border: 1px solid @light-mercury-color;
  overflow: hidden;
  // box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
  //   0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);

  .file-preview-table-header-wrapper {
    padding: 1rem 1rem 0 1rem;
  }

  .file-preview-table-header {
    .filter-icon {
      border: 1px solid @black-color;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
    }

    .filter-search-input {
      border: 1px solid @black-color;
      border-radius: 0.5rem;
      width: 250px;

      .ant-input-group-addon {
        background: transparent;
        border: none;
      }

      .ant-input {
        height: 2.5rem;
        background: none;
        border: none;
        box-shadow: none;
        font-size: 1rem;
      }
    }
  }
}
.file-preview-table {
  .file-preview-table-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    gap: 0.25rem;
  }

  .ant-table-thead {
    .ant-table-cell::before {
      display: none;
    }
    > tr > th {
      background-color: @white-color !important ;
      font-weight: bold;
      font-size: 0.75rem;
      color: @outer-space-color;
    }
  }

  .ant-table-tbody {
    font-size: 0.75rem;
    color: @outer-space-color;

    tr:nth-child(odd) {
      background-color: @off-catskill-white-color;
    }
    tr:nth-child(even) {
      background-color: @white-lilac-color;
    }
    > tr > td {
      border-bottom: 1px solid @light-mercury-color !important;
    }
  }
  .ant-pagination-options {
    display: none !important;
  }
}

.file-popover-content {
  width: 400px;
  .file-popover-title {
    background-color: @off-catskill-white-color;
    padding: 0.5rem;
  }
  .file-popover-footer {
    background-color: @off-catskill-white-color;
    padding: 0.5rem;
  }
  .ant-checkbox-group {
    padding: 0.5rem;
    gap: 0.5rem;
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border-color: @black-color;
    }
    .ant-checkbox-group-item {
      border: 1px solid @off-catskill-white-color;
      margin: 0;
      padding: 0.25rem;
      font-weight: bold;
      .ant-checkbox:hover .ant-checkbox-inner {
        border-color: @black-color;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @black-color;
        border: @black-color;
      }
    }
  }
}

// Dev Comment :-
// .ant-popover-inner-content {
//   padding: 0;
// }

.file-preview-modal {
  .ant-table tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
  }
  tr:hover {
    background-color: @off-catskill-white-color;
  }
  .ant-table tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
  }
}

.file-close-btn:hover,
.file-close-btn {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.file-icons {
  background-color: @white-color;
  padding: 0.5rem;
  border: 1px solid @black-color;
  border-radius: 0.5rem;
  font-size: 1.25rem;
}

.upload-file-divider {
  border-top: 1px solid @botticelli-color;
}

.refresh-interval-text {
  font-size: 1rem !important ;
  color: @outer-space-color !important ;
  text-align: end;
  margin: 0 !important;
}

.managefile-tooltip {
  p {
    font-size: 0.75rem;
    // color: @black-color;
    margin: 0;
  }
}

.csvupload-tooltip {
  p {
    text-align: right;
  }
}

.multi-worksheet-form {
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-label {
    padding: 0;
    label {
      color: @light-dove-gray-color;
      font-size: 1.125rem;
    }
  }
  .ant-select-selection-item {
    text-align: left;
    font-family: "Matter-bold";
  }
}

.ant-tooltip-inner {
  background-color: @light-black-color !important;
  color: @white-color !important;
  font-weight: 500;
  border-radius: 0.375rem !important;
  padding: 0.5rem 1rem !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(6px) !important;
}
.ant-tooltip-content {
  border: 2px solid @primary-color;
  border-radius: 0.5rem;
  .ant-tooltip-arrow-content::before {
    border: 12px solid @primary-color;
  }
}

.configure-table-modal {
  .ant-modal-header {
    background-color: @off-catskill-white-color;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid @botticelli-color;
  }

  .configure-table-header {
    font-size: 1.375rem !important;
    color: @outer-space-color;
    margin: 0;
  }

  .configure-table-wrapper {
    .ant-table {
      background: none;
    }
    .ant-table-thead {
      .ant-table-cell {
        font-size: 1rem;
        color: @white-color;
        background-color: @black-color;
      }
    }

    tr:nth-child(odd) {
      background-color: @off-catskill-white-color;
    }
    tr:nth-child(even) {
      background-color: @white-lilac-color;
    }
    .ant-table-cell {
      border: none;
    }

    .ant-table-row:hover {
      background: none;
      .ant-table-cell {
        background: none;
      }
    }

    .column-name-wrapper {
      font-size: 0.875rem;
      color: @tiber-color;
    }
  }
}

.configure-table-preview {
  .ant-table-thead {
    .ant-table-cell::before {
      display: none;
    }
    .ant-table-cell {
      font-size: 1.125rem;
      color: @outer-space-color;
      background-color: @white-color;
      font-family: "Matter-bold";
      border-bottom: 1px solid @botticelli-color;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-size: 1rem;
      color: @outer-space-color;
      border-bottom: 1px solid @botticelli-color;
    }
  }
}

.carousel-container {
  .slick-dots-bottom {
    justify-content: start !important;
    margin: 0 !important ;
    bottom: -15% !important;
    width: max-content !important;
    li,
    button {
      height: 1rem !important ;
    }

    li.slick-active {
      width: 1rem !important;
      button {
        background-color: @black-color !important ;
      }
    }

    li {
      // pointer-events: none;
      button {
        border-radius: 1rem !important;
        background-color: @barley-white-color !important;
        opacity: 1 !important;
      }
    }
  }
}

.slick-next {
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: -100%;
    right: -603%;
    width: 200px;
    height: 200px;
    background-color: @primary-color;
    border-radius: 100px;
    z-index: -1;
  }
  .anticon-arrow-right {
    svg {
      transform: translateX(-8px);
    }
  }
}

.skip-for-now-text {
  font-size: 1.5rem;
  font-family: "Matter-bold";
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  cursor: pointer;
}

.last-slick-slide {
  &::after {
    display: none;
  }
}

.file-preview-tabs {
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  overflow: hidden;
  .ant-tabs-nav-list,
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    border-bottom: 1px solid @greyser-color;
    border-radius: 0;
  }
}
.uploadedmultisheet-container {
  height: 480px;
  position: relative;
  .refresh-interval-image {
    height: 180px;
  }
}

.emailid-copyable {
  background-color: @energy-yellow-color;
  padding: 0.5rem;
  border-radius: 1.5rem;
  flex-direction: row-reverse;
  gap: 0.25rem;
  display: flex !important ;
  align-items: center;
  transition: all 0.6s ease-in-out;
  // cursor: pointer;

  .ant-typography-copy {
    background-color: @black-color !important ;
    color: @white-color !important ;
    border-radius: 50%;
    height: 2rem;
    padding: 0.25rem 0.5rem !important ;
    display: flex !important ;
    justify-content: center;
    align-items: center;
  }

  .text-two {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .text-one,
  .text-two {
    transition: all 0.6s ease-in-out;
  }

  // &:hover {
  //   .text-one {
  //     width: 0;
  //     height: 0;
  //     opacity: 0;
  //     overflow: hidden;
  //   }
  //   .text-two {
  //     display: flex;
  //     width: fit-content;
  //     height: auto;
  //     opacity: 1;
  //     cursor: pointer !important ;
  //   }
  // }
}

.worksheet-container {
  line-height: 1.5rem !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
  padding: 0 1rem !important;
  display: flex !important ;
  align-items: center !important;
  justify-content: center !important;
}

.recent-files-tab-container {
  .ant-tabs-nav-list {
    background: none !important ;
    padding: 0;
    margin: 0.5rem 0;
    gap: 1rem;
  }

  .ant-tabs-tab-active {
    // background: @primary-color !important;
    // border-color: @primary-color !important;
    .ant-tabs-tab-btn {
      color: @primary-color !important;
    }
  }

  .ant-tabs-tab {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important ;

    // &:hover {
    //   // background: @black-color !important;
    //   // border-color: @black-color !important;

    //   .ant-tabs-tab-btn {
    //     // color: @white-color !important ;
    //   }
    // }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

.recent-files-tab-container-hidden{
  .ant-tabs-nav-list{
    opacity: 0 ;
  }
}

.process-container {
  text-align: end;
}

.nodata-container {
  display: flex;
  background: @white-color;
  border-radius: 0.5rem;
  border: 1px solid @light-mercury-color;
  justify-content: center;
  padding: 2rem 0;
}

.refreshinterval-tag {
  font-size: 1rem !important ;
  padding: 0.25rem 0.5rem !important;
  border-radius: 0.5rem !important;
}

.edit-preview-table {
  .ant-table-tbody {
    .ant-table-row:nth-child(2) {
      background-color: @white-color;
      td {
        color: @cadet-blue-color;
        font-weight: bold;
      }
    }
  }
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;