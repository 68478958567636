@import "/ThemeVariables.less";

.managedatasets-table-container {
  border-radius: 0.5rem;
  border: 1px solid @light-mercury-color;
  overflow: hidden;

  .ant-table-thead {
    background-color: @white-color;

    .ant-table-cell::before {
      display: none;
    }
    .ant-table-cell {
      font-weight: bold;
      border-color: @light-mercury-color;
    }

    > tr > th {
      background: none !important ;
    }
  }
  .ant-table-cell {
    color: @outer-space-color;
    font-size: 1rem;
    font-weight: 500;
  }

  .ant-table-tbody {
    tr {
      background-color: @light-zircon-color;
    }
    > tr > td {
      border-color: @light-mercury-color;
    }
  }
  .dataset-name-container {
    display: flex;
  }

  .dataset-name-container-connected:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.dataset-edit-btn {
  height: 1.5rem;
  width: 1.5rem;
  border: none;
}

.save-btn {
  padding: 0 1rem !important;
}

.editable-btns-container {
  height: 2.5rem;
}

.bb-form-input:disabled {
  color: @black-color !important;
}

.manage-dataset-container {
  .file-preview-table {
    .ant-table-thead {
      .ant-table-cell {
        padding-left: 0;
        padding-bottom: 0;
        font-family: "Matter-bold";

        .ant-input {
          text-overflow: ellipsis;
          color: @river-bed-color;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        color: @mirage-color;
      }
    }
  }
}

.platform-manager-role {
  font-weight: bold;
  background-color: @black-color;
  color: @white-color;
  padding: 0.5rem 1rem;
  border-radius: 2rem 0 0 2rem;
  position: absolute;
  right: -24px;
}

.advanced-table-container {
  .ant-table-cell[colspan="6"] {
    border-top: 1px solid @black-color !important;
    border-bottom: 0 !important;
    padding: 0px 16px 0px 16px;
    .preconfigure-title {
      background: @black-color;
      color: @white-color;
      border-radius: 0px 0px 5px 5px;
      width: fit-content;
      padding: 0.25rem 0.675rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-transform: capitalize;
    }
  }
  .ant-table-cell[colspan="9"] {
    border-top: 1px solid @black-color !important;
    border-bottom: 0 !important;
    padding: 0px 16px 0px 16px;
    .preconfigure-title {
      background: @black-color;
      color: @white-color;
      border-radius: 0px 0px 5px 5px;
      width: fit-content;
      padding: 0.25rem 0.675rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-transform: capitalize;
    }
  }
  .ant-empty {
    padding: 16px;
  }
}

.advanced-table-container {
  .ant-table-cell {
    font-weight: 600 !important;
  }

  .advanced-list-in-progress {
    .ant-table-cell {
      color: #9e9e9e !important;
      .ant-typography {
        color: #9e9e9e !important;
      }
    }
  }
}

.advancedlist-container {
  background-color: @white-color;
  border-radius: 0.5rem;
  border: 1px solid @light-mercury-color;
  padding: 1rem 0;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;