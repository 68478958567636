@import "/ThemeVariables.less";

//**************************************************************************************
//****************************** School Settings Start ********************************//
//**************************************************************************************
.school-settings-tab {
  padding: 1rem;
  border-radius: 0.5rem;
  min-height: 85vh;
}

.school-settings-header {
  display: flex;
  align-items: center;
  // border-bottom: 1px solid @greyser-color;
  // padding-bottom: 0.675rem;
}

//**************************************************************************************
//****************************** School Settings End **********************************//
//**************************************************************************************

.data-source-panel .ant-collapse-header-text {
  width: 100% !important;
}

.data-source-panel {
  align-items: stretch;
  filter: drop-shadow(0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1));
  border: 1px solid @botticelli-color;
  border-radius: 00.5rem !important;
  background-color: @white-color;
}

.data-source-panel .ant-collapse-header {
  align-items: center !important;
  padding: 0 !important;
  background-color: @zircon-color;
  border-radius: 0.5rem !important;
}

.data-source-panel .anticon-caret-right {
  font-size: 1.25rem !important;
  margin: 0 0 0 0.875rem !important;
}

.data-source-title {
  font-family: "Matter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem !important;
  margin: 0 !important;
  font-family: "Matter-bold";
}

.data-source-wrapper,
.data-source-title-wrapper {
  display: flex;
  gap: 1.5rem;
  margin-top: 10px;
}
.data-source-title-wrapper {
  align-items: center;
}

.data-source-carousel {
  display: flex;
  justify-content: space-between;
}

.data-source-carousel .slideHorizontal___1NzNV {
  height: 200px !important;
}

.carousel-header {
  font-family: "Matter-bold";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  color: @outer-space-color;
  padding: 0.625rem 0.625rem 0 0.625rem;
}

.data-source-time-container {
  font-family: "Matter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  line-height: 1;
}

.data-source-panel .carousel__slider-tray {
  display: flex;
  gap: 1rem;
}

.data-source-panel .carousel__inner-slide {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}

.data-source-status-container {
  align-items: center;
  font-size: 14px;
}
.data-source-time-container {
  font-size: 14px;
}

.data-source-status-time-container {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-top: 0.25rem;
  justify-content: center;
}

.carousel__slider {
  padding: 0 0.5rem !important ;
}

.secret-key-header-container {
  justify-content: space-between;
  background-color: @zircon-color;
  align-items: center;
  // padding: 1rem;
  // border-radius: 0.5rem 0.5rem 0 0;
  // border-bottom: 1px solid @botticelli-color;
}

.secret-key-header {
  font-family: "Matter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
}

.secret-key-container {
  border-radius: 0.5rem;
  filter: drop-shadow(0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1));
  border: 1px solid @botticelli-color;
  height: 250px;
  background-color: @white-color;
}

.secret-key {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: @off-catskill-white-color;
  padding: 0.5rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
  width: 100%;
}

.secret-key-content-container {
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  height: calc(100% - 67px);
  flex-direction: column;
  gap: 0.875rem;
}

.secret-key-text {
  font-family: "Matter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.3;
}

.organization .ant-tabs-nav::before {
  border-bottom: none;
}

.secret-key-visible {
  filter: blur(0.25rem);
}

.school-description-container .ant-descriptions-view {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid @botticelli-color !important;
}

/* .school-description-container{
  border: 1px solid #D3DFEA;
} */

.invite-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem 0 2rem;
  background-color: @citrine-white-color;
  border-radius: 0.5rem;
  filter: drop-shadow(0px 4px 4px @off-black-color);
}

.invite-text-container {
  height: 125px;
}

.invite-team-member {
  font-size: 1.125rem !important;
  text-align: center;
  line-height: 1.5rem;
  font-weight: 600;
}

.user-details-action-wrapper {
  background-color: @white-color;
  margin: 0.25rem 0;
  padding: 1rem !important;
  border-radius: 0.25rem;
}

.user-details-list-item {
  background: @zircon-color;
  padding: 0.675rem !important ;
  margin-bottom: 0.125rem;
}

.user-details-list-item-button {
  background: none;
  width: auto;
  height: auto;
  font-weight: 600;
}

.user-details-list-item-button-primary {
  color: @primary-color;
  border-color: @primary-color;
}

.user-details-list-item-button-outer-space {
  color: @outer-space-color;
  border-color: @outer-space-color;
}

.data-source-collapse {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: @off-catskill-white-color !important;
}

.secret-key-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 14px;
  width: 100%;
}

.secret-key-buttons-container {
  display: flex;
}

.delete-btn {
  font-size: 1rem;
  color: @black-color;
  height: auto;
}

.add-btn {
  margin: auto 0;
  font-size: 0.875rem;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
}

.enable-btn {
  color: @black-color;
}

.disable-btn {
  color: @dust-gray-color;
}

.suspend-company-text {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-weight: 600;
}

.suspend-company-btn-container {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
}

.piiSwitch {
  .ant-switch-inner {
    color: @black-color;
  }
}

.school-detail {
  .ant-collapse-header-text,
  .ant-collapse-content {
    padding: 1rem;
  }
}

.pii-item-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: @mine-shaft-color;
  font-size: 1.125rem;
  font-weight: bold;
}

.pii-description {
  border: 1px solid @catskill-white-color;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.pii-information-text {
  font-size: 1.125rem;
  line-height: 1;
  color: @tundora-color;
}

.pii-field-container {
  font-weight: 600;
  color: @primary-color;
  font-size: 1.125rem;
}

.pointers-none {
  cursor: not-allowed !important;
}

.advanced-list-container {
  border-radius: 0.5rem;
  background: @citrine-white-color;
  padding: 2rem;
  box-shadow: 0 0 0.635rem @shadow-color;
}

.advanced-list-container-image {
  transform: scale(1.3);
}

.data-provider-heading {
  color: @outer-space-color;
  // width: 100%;
  padding: 0 0 0.925rem 0;
  border-bottom: 1px solid @botticelli-color;
  // display: flex;
  // justify-content: space-between;
  // gap: 1rem;
  font-weight: 700 !important ;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;