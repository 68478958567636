//** Theme Variables imports */
@import "/ThemeVariables.less";
//**************************************************************************************
//***************************** Auth pages styling Start ******************************//
//**************************************************************************************

//** Auth login background  */
.login-bg {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/background.svg");
  padding: 3.125rem 1.875rem;
}

//** Auth Col One Heading */
// .login-heading-text {
//   font-size: 2rem;
//   line-height: 2.5rem;
//   color: black;
//   font-weight: bold;
// }

//** Auth Col One Sub-Heading */
.login-subheading-text {
  font-size: 2rem;
  line-height: 2.5rem;
  color: black;
}

//**************************************************************************************
//***************************** Auth pages styling End *******************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//***************************** Navbar Styling Start *********************************//
//**************************************************************************************
//******************** Large Screen Input box styling start ***************************/
.search-large-screen {
  display: flex;
}
.search-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
  width: 100%;
  height: 4rem;
}

.search-wrapper .ant-space-align-center {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.search-wrapper .ant-space-align-center .ant-space-item:nth-child(1) {
  padding-left: 1.5rem;
}

.search-wrapper .search-input-wrapper {
  display: flex;
  justify-content: flex-end;
}

.search-container .ant-input-group-addon {
  position: absolute;
  left: 0;
  top: 0;
}

.search-container .ant-input-group {
  border-radius: 0 !important;
}

.search-container .ant-input-search-button {
  background-color: transparent;
  border: 0;
  z-index: 999;
}

.search-container .ant-input {
  position: relative;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}
.search-container .ant-input {
  background-color: @athens-gray-color;
}
.searchbar .ant-input-wrapper {
  position: relative;
}
.search-view-container .ant-input-search-button {
  display: none;
}
//******************** Large Screen Input box styling end ***************************/
//**************************************************************************************
//***************************** Navbar Styling Start *********************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//***************************** Footer Styling Start *********************************//
//**************************************************************************************
//** Footer Container */
.footer-container {
  color: @mine-shaft-color;
  font-size: 0.875rem;
  text-align: center;
  font-weight: 300;
  padding: 0.875 0;
  position: fixed;
  bottom: 1.25rem;
}

.main-layout-footer {
  padding: 0 !important;
  background-color: @black-color !important;
  color: @white-color !important;
  text-align: center;
  font-weight: 500;
}
//**************************************************************************************
//***************************** Footer Styling End *********************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//****************************** Sider Styling Start *********************************//
//**************************************************************************************
.slidermenu.darkMode #site-layout .site-layout-background {
  background: @white-color;
}
.slidermenu.darkMode .ant-layout-sider-children {
  background: @black-color;
}
// .slidermenu .ant-menu-item-selected {
//   background-color: @primary-color !important;
// }

.ant-menu {
  background-color: @black-color !important;
}

.ant-menu-item-selected {
  border-radius: 0.5rem;
}

.ant-menu-item {
  &:hover {
    border-radius: 0.5rem;
    background-color: rgb(65, 51, 0) !important;

    .ant-menu-title-content {
      span {
        color: @primary-color !important;
      }
    }
  }
}

.slidermenu .ant-menu-item-selected {
  background-color: @cola-color !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @cola-color !important;
}
.slidermenu.darkMode .ant-menu-item-selected .ant-menu-title-content span {
  color: @primary-color !important;
}
.slidermenu.darkMode .ant-menu-item-selected .anticon svg {
  color: @primary-color !important;
}

.explores-sider-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  overflow: scroll;
  .sider-menu-container {
    position: relative;
    z-index: 999;
  }
}
//**************************************************************************************
//****************************** Sider Styling End ***********************************//
//**************************************************************************************
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//**************************************************************************************
//****************************** Avatar Menu Start ***********************************//
//**************************************************************************************
.settingsMenu {
  width: 8.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.settingsMenu .ant-dropdown-menu-title-content {
  font-size: 0.875rem;
  font-weight: 600;
}
.settingsMenu .ant-dropdown-menu-item:hover,
.settingsMenu .ant-dropdown-menu-item-selected {
  background-color: @alabaster-color !important;
}

.settingsMenu .ant-dropdown-menu-title-content a:hover,
.settingsMenu .ant-dropdown-menu-item-selected a,
.settingsMenu .ant-dropdown-menu-item-selected a:hover {
  color: @primary-color !important;
}

//**************************************************************************************
//****************************** Avatar Menu Start ***********************************//
//**************************************************************************************

.notification-popover {
  background: @white-color;
  box-shadow: 0px 1px 0px #e4e8ee;

  &::-webkit-scrollbar {
    display: none;
  }

  .notification-title {
    padding: 0.5rem 0rem;
  }

  .popup-title {
    color: @light-mirage-color;
    margin: 0 !important;
  }

  .notifications-actions {
    padding: 0 1rem;
    .mark-as-read-title {
      display: flex;
      gap: 0.5rem;
      color: @husk-color;
    }

    .notification-select {
      z-index: 9999;
      .ant-select-selector {
        border: none;
        box-shadow: none;
      }
    }
  }

  .set-preference {
    color: @primary-color;
    margin: 0 !important;
    font-family: "Matter-bold";
    cursor: pointer;
  }

  .ant-popover-title {
    border: none;
  }

  .ant-popover-arrow {
    // right: 0;
    display: none;
  }
  .ant-popover-inner-content {
    width: 100%;
    padding: 0;
    min-height: 11rem;

    .notification-icon {
      background-color: @bright-sun-color;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
    }
    // .is-read-container {
    //   background: #f8f8f8;
    //   // color: #f8f8f8;
    // }

    .notification-container {
      border-bottom: 1px solid @mystic-color;
      .ant-comment-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.25rem;
      }

      .ant-comment-content-detail {
        color: @light-mirage-color;
        font-size: 1rem;
        font-weight: 600;
      }
      .ant-comment-content-author-time {
        color: @gray-chateau-color;
        font-weight: 500;
        margin: 0;
        width: 100%;
      }
      .ant-comment-inner {
        padding: 0.5rem 1rem;
        &:hover {
          background-color: @early-down-color;
          .notification-readed-color {
            color: @light-mirage-color;
          }
        }
      }

      .notification-readed-bg {
        background: #ededed;
      }
      .notification-readed-color {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.notification-modal {
  .notification-title {
    font-family: "Matter-bold";
    margin: 0;
    color: @outer-space-color;
  }
  .notification-text {
    color: @outer-space-color;
    font-weight: 500;
  }

  .ant-tabs-tab-btn {
    font-size: 1rem;
    color: @outer-space-color;
    font-weight: 500;
  }

  .data-sync-tabs {
    .ant-tabs-tab {
      margin: 0 !important;
    }
    .ant-tabs-ink-bar {
      display: flex;
    }
    .ant-tabs-nav-wrap {
      width: 100%;
      line-height: 1;
      .ant-tabs-nav-list {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .ant-tabs-tab {
          font-family: "Matter-bold";
        }
      }

      .ant-tabs-ink-bar-animated {
        display: none;
      }
    }
    .ant-tabs-tab-active {
      background-color: transparent !important;
      border-radius: 0rem !important;
      .ant-tabs-tab-btn {
        color: @primary-color !important;
      }
    }
  }

  .ant-tabs-tab {
    margin: 0 1.5rem 0 0 !important;
    font-family: "Matter-bold";
  }

  .notification-tag {
    color: @black-color;
    border-radius: 1rem;
    padding: 0 1.75rem;
  }
  .notification-type-title {
    line-height: 1.25;
  }

  .ant-tabs-tab-active {
    background-color: @primary-color;
    border-radius: 0.875rem;
    .ant-tabs-tab-btn {
      color: @black-color !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: end;
  border: none;
}

.single-dataprovider {
  padding-left: 2.5rem !important;
}

.sider-submenu-container {
  padding-left: 3.125rem !important;
}

@media screen and (max-width: 990px) {
  .sider-submenu-container {
    padding-left: 0rem !important;
  }
  .single-dataprovider {
    padding-left: 0.625rem !important;
  }
}

.header-breadcumb {
  width: 100%;

  ol {
    width: 100%;
    li:nth-child(1) {
      display: flex;
      align-items: center;
    }
  }
  .ant-breadcrumb-separator {
    color: @black-color;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.scoping-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 5px solid @primary-color;
  z-index: 999;
  pointer-events: none;
  

  .companyname-container {
    background: @primary-color;
    display: flex;
    align-items: center;
    padding:  0.25rem 1rem 0.5rem 1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    color: @black-color;
    font-weight: bold;
    font-size: .75rem;
    height: 2rem;
  }

}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;