/* mobile screen  */

@media screen and (min-width: 320px) and (max-width: 780px) {
  .ant-layout-sider-collapsed {
    flex: 0 0 0px;
    // max-width: 80px !important;
    // min-width: 80px !important;
    // width: 0px;
  }
  .ant-layout-sider-collapsed .logo {
    height: 25px !important;
  }
  .logo {
    margin-bottom: 30px !important;
  }
  .ant-layout-sider-collapsed ul li {
    padding: 0 calc(50% - 25px / 2) !important;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
  }
  .ant-layout-sider-collapsed ul li svg {
    display: flex;
    margin: auto;
  }
  .version {
    display: none;
  }
  .card-responsive {
    padding-bottom: 30px !important;
  }
  .billing-container {
    padding: 30px;
  }
  .profiletab .ant-tabs-nav {
    height: 175px;
  }

  .profiletab .ant-tabs-nav-list {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .organization .ant-tabs-nav-list {
    width: 100% !important;
    display: flex;
    /* padding: 10px; */
    overflow-x: scroll;
  }

  .organization .ant-tabs-nav {
    height: auto !important;
  }
  .companySettingTab .ant-tabs-ink-bar {
    background: transparent !important;
  }
  .companySettingTab .ant-tabs-ink-bar {
    right: auto !important;
    left: auto !important;
  }
  .max-width {
    height: 71vh !important;
  }
  .school-detail .ant-descriptions-bordered .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }
  .companySettingTab .ant-descriptions-item-label {
    width: 100% !important;
  }
  .refresh-icon {
    height: 25px !important;
  }
  .school-form {
    // display: block;
    flex-direction: column;
  }
  .school-form .ant-select,
  .company-form .ant-input,
  .user-form .ant-select,
  .user-form .ant-input {
    width: 100% !important;
  }
  .companysearchbtn .ant-col.ant-form-item-control {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .companysearchbtn .ant-form-item-control-input,
  .companysearchbtn button {
    width: 100% !important;
  }
  .company-add-account {
    display: block;
    padding-bottom: 0px;
  }
  .company-add-account button,
  .user-form button {
    width: 100% !important;
  }
  .tablecell {
    overflow-x: scroll;
  }
  .user-form {
    display: block;
  }
  .mainLayout {
    min-height: 100vh;
  }
  .companySettingTab .ant-tabs-nav-wrap {
    padding-top: 0px;
  }

  .companySettingTab .ant-tabs-nav-list {
    margin: 10px 0 0 0;
    /* padding: 10px; */
    height: 2.5rem;
  }

  .integration-tab .ant-list-item {
    display: grid !important;
    grid-template-columns: 60% 40%;
  }

  .integration-tab .connect-btn {
    margin-top: 10px;
  }

  .mainLayout {
    margin: 10px;
  }

  .main-bg {
    padding: 10px !important;
  }

  .selection-footer .footer {
    position: unset !important;
  }

  .footer {
    position: absolute !important;
    bottom: 0 !important;
  }

  .school-information {
    padding-top: 0px;
  }
  .mtop-10 {
    margin-top: 10px;
  }
}

/* ipad  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ant-layout-sider-collapsed {
    flex: 0 0 0px;
    // max-width: 80px !important;
    // min-width: 80px !important;
    // width: 0px;
  }

  .ant-layout-sider-collapsed .logo {
    height: 25px !important;
  }
  .logo {
    margin-bottom: 30px !important;
  }

  .ant-layout-sider-collapsed ul li {
    padding: 0 calc(50% - 25px / 2) !important;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
  }

  .ant-layout-sider-collapsed ul li svg {
    display: flex;
    margin: auto;
  }

  .version {
    display: none;
  }

  .max-width {
    height: 100% !important;
  }
  .login-bg {
    display: none;
  }
  .billing-container {
    padding: 30px;
  }
  .card-use-to-item {
    font-size: 9px !important;
  }
  .righticon {
    height: 10px !important;
  }
  .companySettingTab .ant-tabs-nav-list {
    display: flex;
    justify-content: flex-start;
  }
  .organization .ant-tabs-nav-list {
    overflow-x: none;
  }
  .school-form {
    display: flex;
    justify-content: flex-end;
  }
  .company-add-account {
    display: flex;
  }
  // .company-add-account button,
  // .user-form button {
  //   width: auto !important;
  // }
  .user-form {
    display: flex;
    justify-content: center;
  }

  .invite-user .ant-col-md-8 {
    display: block;
    flex: 0 !important;
    max-width: fit-content !important;
  }
  .profiletab .ant-tabs-nav {
    height: 220px !important;
  }
  .integration-tab .ant-list-split {
    min-height: 40vh;
  }

  .mainLayout {
    height: auto;
  }

  .companySettingTab .ant-tabs-nav-wrap {
    padding-top: 0px;
  }

  .companySettingTab .ant-tabs-nav-wrap {
    padding: 0;
    margin: 10px 0;
    height: 2.5rem;
  }

  .companySettingTab .ant-tabs-tab {
    margin-top: 1rem;
  }

  .school-information {
    padding-top: 5px;
    margin-bottom: 0px;
  }

  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }
  .home-left-section {
    width: 100%;
  }

  .home-right-section {
    width: 100%;
    padding: 20px 10px;
  }

  .home-section-container {
    display: block;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }

  .help-search-container {
    background-size: contain;
    padding: 20px;
  }

  span.help-header-text {
    font-size: 1rem;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .btn-primary {
    padding: 0 1rem !important;
  }

  .home-left-section {
    width: 100%;
  }

  .home-right-section {
    width: 100%;
  }

  .home-section-container {
    flex-direction: column !important ;
  }
  .home-container {
    background-size: 180px;
    background-position: right bottom;
    height: auto;
    padding: 25px;
  }

  .home-col-2 img {
    height: auto;
    width: auto !important;
    max-width: 140px;
    max-height: 120px;
    object-fit: contain;
    transform: translateX(10px);
  }

  .home-col-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  h1.ant-typography.home-title {
    font-size: 1.125rem !important;
  }

  .flex-col.home-container span {
    font-size: 1rem;
  }

  .home-left-title {
    font-size: 1rem !important;
    margin: 0 0 0.5rem 0 !important ;
    padding: 0 !important ;
  }

  .home-left-section button.ant-btn.btn-primary {
    font-size: 1rem !important;
  }

  .home-col-1.flex-col {
    justify-content: center;
  }

  .home-left-section .flex-row {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home-right-section.flex-center {
    padding: 20px 5px;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }

  .help-search-container {
    background-size: contain;
    padding: 20px;
  }

  span.help-header-text {
    font-size: 1rem;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
  .data-source-box .title-wrapper {
    display: block;
  }

  .data-source-box .datasource-container-col-1 {
    width: 100%;
  }

  .data-source-box .datasource-container-col-2 {
    width: 100%;
    padding-top: 0;
    padding-left: 20px;
  }

  .data-source-box .ant-collapse-expand-icon {
    position: absolute;
    right: 30px;
    transform: scale(1.5);
    top: 10px;
  }

  .data-source-box .datasource-container-col-2 > div {
    width: 55% !important;
  }

  .data-source-box .datasource-container-col-2 button {
    margin-top: -5px;
  }
  .invite-container {
    text-align: center;
    display: block;
    padding: 15px !important;
  }

  .invite-container img {
    width: 50%;
  }
  .invite-text-container {
    height: auto !important;
  }

  .invite-text-container button.ant-btn.ant-btn-default.btn-primary {
    white-space: normal;
    padding: 5px 10px;
    height: auto;
    margin-top: 10px;
  }

  // .data-source-title {
  //   font-size: 1rem !important;
  // }
}

@media screen and (max-width: 600px) {
  .school-tab-box .ant-tabs-tab > div {
    margin-left: 0 !important;
    width: 100%;
    text-align: center;
  }

  .school-tab-box .ant-tabs-nav-list {
    padding: 0 10px !important;
  }

  .school-tab-box .ant-tabs-tab .ant-tabs-tab-btn {
    white-space: normal;
    text-align: center;
    border-left: crimson;
  }

  .school-tab-box .ant-tabs-tab {
    margin-left: 20px !important;
  }

  .school-tab-box .ant-tabs-nav-list > .ant-tabs-tab:first-child {
    margin-left: 0 !important;
  }
  .secret-key-header-container {
    display: block !important;
  }
  .secret-key-header-container .secret-key-header {
    display: block;
  }

  .school-description-container .ant-descriptions-item-label,
  .school-description-container .ant-descriptions-item-content {
    padding: 5px 10px;
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .data-source-box .datasource-container-col-2 {
    display: block;
    text-align: center;
  }

  .data-source-box .datasource-container-col-2 button {
    margin-top: 15px !important;
    margin-bottom: 5px;
  }

  .data-source-box .datasource-container-col-2 > div {
    width: 100% !important;
  }
  .home-col-2 img {
    max-width: 100px;
    height: 80px;
  }

  .pricing-bullet-points {
    padding: 10px !important;
    border-radius: 10px !important;
    margin-top: 1rem;
  }
  .schoolbi-plan-heading {
    font-size: 25px !important;
  }

  .invite-user {
    padding-left: 0 !important;
  }

  .inviteIMG {
    height: 150px !important;
  }
  .user-search-container {
    margin-top: 10px !important;
  }

  .file-icon {
    margin-right: 10px;
  }

  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }

  .top-up-btn-wrapper {
    margin-left: 0px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }

  .d-none {
    display: none !important;
  }

  .search-container {
    margin-bottom: 8px;
  }

  .search-container,
  .search-container .ant-space,
  .header-wrapper .ant-input-wrapper {
    width: 100% !important;
  }

  .search-wrapper .ant-item:nth-child(2) {
    width: 100%;
  }

  .search-view-container {
    display: flex;
  }

  /* .search-container .ant-input-wrapper{
        width: 0;
    } */

  .search-wrapper .ant-space-align-center .ant-space-item:nth-child(1) {
    width: 100%;
  }

  .search-container .ant-space-item:nth-child(1) {
    width: 100%;
    margin-left: 10px;
  }

  .school-form-input {
    margin-right: 0;
  }

  .profiletab .ant-tabs-nav {
    height: 50px !important;
  }

  .companySettingTab .ant-tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
  }
  .search-school-container {
    width: 100%;
  }
  .company-setting-wrapper .ant-tabs-nav-list {
    padding: 10px;
    gap: 1rem;
  }

  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }
  .userTab .ant-tabs-nav-list {
    padding-bottom: 0;
    padding-left: 0;
  }

  .search-school-container {
    width: 100%;
  }

  .home-container {
    background-size: 140px;
    height: auto;
    padding: 15px;
  }
  .home-left-section .flex-row {
    padding: 15px;
  }

  .invite-container img {
    width: 75%;
  }

  .help-search-container {
    height: 150px;
    .help-search-title {
      font-size: 1.25rem;
    }

    .help-search-subtitle {
      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 420px) {
  .school-tab-box .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px !important;
  }
  .school-tab-box .ant-tabs-tab {
    margin-left: 10px !important;
  }

  .school-tab-box .ant-tabs-nav-list > .ant-tabs-tab:first-child {
    margin-left: -6px !important;
  }
  .secret-key-header-container button.ant-btn.ant-btn-default.btn-primary {
    max-width: 100%;
    white-space: normal;
    height: auto !important;
    padding: 10px 10px;
  }
  .secret-key-content-container {
    display: block !important;
    text-align: center;
  }

  .secret-key-content-container span {
    display: block;
    font-size: 1rem;
    margin: 10px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .pricing-bullet-points {
    padding: 10px !important;
    border-radius: 10px !important;
    margin-top: 1rem;
  }
  .schoolbi-plan-heading {
    font-size: 25px !important;
  }

  .file-icon {
    margin-right: 10px;
  }

  .top-up-btn-wrapper {
    margin-left: 50px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }

  .userTab .ant-tabs-tab {
    padding-bottom: 0.75rem;
  }

  .search-school-container {
    width: 100% !important ;
  }

  .school-form-input {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 825px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }

  .selectedScreen {
    padding: 30px 0;
  }

  .top-up-btn-wrapper {
    margin-left: 50px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }
  .data-source-clicker span {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 825px) and (max-width: 992px) {
  .selectedScreen {
    padding: 30px 0;
  }

  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 2px !important ;
  }

  .data-source-clicker {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 3px !important;
  }
  .selectedScreen {
    padding: 30px 0;
  }
  .top-up-btn-wrapper {
    margin-left: 35px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .file-icon {
    height: 25px;
    margin-top: 5px;
    margin-right: 3px !important;
  }

  .top-up-btn-wrapper {
    margin-left: 25px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }
  .home-left-section .flex-row {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .file-icon {
    height: 30px;
    margin-top: 5px;
  }
  .top-up-btn-wrapper {
    margin-left: 35px;
  }
  .data-source-clicker {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2400px) {
  .top-up-btn-wrapper {
    margin-left: 50px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 2400px) {
  .top-up-btn-wrapper {
    margin-left: 50px;
  }

  .data-source-clicker {
    padding: 0px !important;
  }
}

@media screen and (max-width: 992px) {
  #menu-container .layout {
    // margin-left: 80px !important ;
    z-index: 1;
    transition: 0.2s;
  }
  .search-school-container {
    width: 100%;
  }

  .companysearchbtn .ant-form-item-control-input,
  .companysearchbtn button {
    width: 100% !important;
  }

  .back-to-admin-link {
    display: none !important;
  }
  // Dev Comment :-
  // .emailid-container {
  //   flex-direction: column;
  // }
}

@media screen and (max-width: 786px) {
  .allplan-container {
    flex-direction: column;
    border: none;
  }
  .currentplaninfo-container {
    width: 100%;
  }
  .process-container {
    text-align: end;
  }

  .help-md-container {
    .ant-drawer-content-wrapper {
      width: 100% !important ;
    }
  }
}
