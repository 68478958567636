@import "~antd/dist/antd.less";
@import "index.less";

.ant-modal-content {
  border-radius: 10px !important;
}
input {
  border-radius: 8px !important;
}

.secret-key-value {
  color: #959595;
}

.center_box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-fluid {
  max-width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-modal-content {
  border-radius: 10px !important;
}
input {
  border-radius: 8px !important;
}

.secret-key-value {
  color: #959595;
}

.center_box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-fluid {
  max-width: 100%;
}
.secret-key-content-container {
  font-size: 1.1rem;
}

/* inline to class css */
.pt_13 {
  padding-top: 13px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mt_8 {
  margin-top: 8px;
}
.py_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.typography-text {
  font-weight: 400;
  justify-content: center;
  font-size: 1rem;
}

.typography-title {
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25rem;
  text-align: center;
}
.modal-btn {
  padding: 16px 0;
  background: #ffc90a;
  border-color: #ffc90a;
  align-items: center;
  justify-content: center;
  color: "#000000";
  font-size: 14px;
  font-weight: 600;
}

.dashboard-card-header {
  font-weight: 600;
  line-height: 25px;
  padding: 10px 0;
  font-size: 18px;
}

.inviteIMG {
  height: 300px;
}

.br_20 {
  border-radius: 20px;
}

.data-card-skeleton .ant-space-item {
  width: 25% !important;
  float: left !important;
  padding: 10px;
}

.data-card-skeleton .ant-space.ant-space-vertical.row {
  display: block !important;
}

.data-card-skeleton .ant-space-item span {
  height: 140px;
  width: 100%;
  border-radius: 10px;
}

.skeleton-title {
  margin-bottom: 10px;
}

.data-card-title .ant-skeleton-element {
  width: 100%;
}

.data-card-title .ant-space-item {
  width: 26% !important;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
}

.ant-space.ant-space-vertical.d-block.clearfix.data-card-title {
  display: block;
}

.data-card-title .ant-space-item:first-child {
  margin-left: 10px;
}

.data-card-title .ant-space-item:nth-child(3) {
  margin-right: 0 !important;
}

.data-card-title .ant-space-item span {
  min-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
}
.skeleteon-help-header {
  margin: 15px;
  height: 250px;
  text-align: center;
}

.skeleteon-help-header {
  margin: 15px;
  height: 250px;
  text-align: center;
}

.skeleton-help {
  width: calc(100% - 30px);
  margin: 15px;
  padding: 10px;
  background: #ffe;
  border-radius: 5px;
}

.skeleton-help span {
  width: 50% !important;
  margin: 0;
  border-radius: 7px;
}

/* custom css class same as bootstrap */

.d-flex {
  display: flex;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.justify-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex-colum {
  flex-direction: column;
}
.text-center {
  text-align: center;
}

.text-grey {
  color: grey;
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.subMenuItem {
  .ant-menu-sub {
    background: none !important ;
  }
  .ant-menu-submenu-title .anticon + span {
    margin-left: 1.5rem;
    font-size: 1.125rem;
  }
  // .ant-menu-submenu-arrow {
  //   display: none;
  // }
}
