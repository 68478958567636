@import "/ThemeVariables.less";

.home-wrapper {
  gap: 1.25rem;
}

.home-container {
  background-color: @citrine-white-color;
  box-shadow: 0px 0px 0.635rem @shadow-color;
  border-radius: 0.5rem;
  height: 24vh;
  padding: 0 1.5rem;
  justify-content: center;
  gap: 0.875rem;
  background-image: url("../../assets/images/Home.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}

.home-title {
  font-weight: 900;
  font-size: 1.875rem !important;
  line-height: 1 !important;
  margin-bottom: 0 !important;
  font-family: "Matter";
  letter-spacing: 0px;
}

.home-text {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1 !important;
}

.home-section-container {
  gap: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.home-left-section {
  width: 50%;
  gap: 1.25rem;
}

.home-right-section {
  width: 50%;
  background: @white-color;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.635rem @shadow-color;
}

.manage-container,
.data-source-container,
.tips-container {
  height: 18vh;
  padding: 0.75rem 1.5rem;
  justify-content: space-between;
  border: 1px solid rgba(230, 229, 242, 0.811762);
  box-shadow: 0px 0px 0.635rem @shadow-color;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}

.manage-container,
.data-source-container {
  background-color: @white-color;
}

.tips-container {
  background-color: @citrine-white-color;
}

.home-col-1 {
  justify-content: space-around;
}

.home-col-2 img {
  height: 15vh;
}

.home-left-title {
  font-weight: 600;
  font-size: 1.25rem !important;
  font-family: "Matter";
  line-height: 1;
}

.home-invite-btn {
  margin: 0 auto;
}

.navigation-link {
  width: fit-content;
  padding: 0 1.5rem !important;
}

.home-card-wrapper {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 8px;
  height: 100%;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;