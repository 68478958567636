@import "/ThemeVariables.less";

.help-search-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: @off-catskill-white-color;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem;
}

.help-drawer-title {
  color: @off-mine-shaft-color;
  font-weight: 600;
}

.help-search-title {
  font-family: "Matter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: @cod-gray-color;
  margin: 0;
}

.help-search-subtitle {
  font-family: "Matter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  text-transform: lowercase;
  color: @off-cod-gray-color;
  line-height: 1;
}

.help-search-container {
  background-image: url("../../assets/images/HelpModule.svg");
  background-color: @catskill-white-color;
  border-radius: 0.5rem;
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  background-position: bottom;
}

.help-input-wrapper .ant-input-affix-wrapper {
  width: 525px;
  display: flex;
  margin: 0 auto;
  border: 0;
  border-radius: 0.25rem;
}

.help-input-wrapper input {
  font-size: 1rem;
}

.help-input-wrapper input::placeholder {
  color: @slate-gray-color;
}

.background-norepeat {
  background-repeat: no-repeat;
}

.help-panel {
  overflow: hidden;
  background: none;
  border: 0px;
  border-radius: 0.5rem !important;
  background-color: @white-color;
}

.help-panel .ant-collapse-extra {
  margin-left: unset !important;
}

.help-collapse {
  display: flex;
  gap: 0.875rem;
  flex-direction: column;
  background: @off-catskill-white-color;
  border-radius: 0.5rem;
}

.help-header-text {
  font-family: "Matter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  color: @black-color;
  margin: 0;
}

.help-collapse-text {
  font-family: "Matter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: @light-dove-gray-color !important ;
  text-align: justify;
  width: 100%;
}

.help-collapse .ant-collapse-content-box {
  padding: 0.75rem !important;
}

.help-panel .ant-collapse-extra {
  width: 100%;
}

.home-panel-extra-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.875rem;
}

.editicon {
  color: @primary-color;
  font-size: 1.25rem;
  &:hover{
    color: @primary-color;
  }
}

.add-new-question {
  border: 1px solid @black-color !important;
  background-color: transparent !important;
}

.add-new-question:focus,
.add-new-question:hover {
  border: 1px solid @primary-color !important;
  color: @primary-color !important;
  background: none;
}

.help-deleteicon {
  color: @primary-color;
  font-size: 1.25rem;
  &:hover{
    color: @error-color;
  }
}

.home-icons {
  display: flex;
  gap: 0.5rem;
}

.home-no-btn {
  color: @black-color !important;
  border: 1px solid @black-color !important;
  font-weight: bold !important;
  font-family: "Matter";
  font-size: 0.875rem !important;
  line-height: 1;
  padding: 0px 1rem;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
}

.home-yes-btn {
  background-color: @primary-color !important;
  color: @black-color !important;
  border: 1px solid @primary-color !important;
  font-weight: bold !important;
  font-family: "Matter";
  font-size: 0.875rem !important;
  line-height: 1;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
}

.isEditable {
  border: 1px solid @primary-color;
  padding: 0 0.875rem;
  border-radius: 0.5rem;
  min-height: 2rem;
}

.save-btn {
  color: @primary-color;
  font-size: 1.25rem;
}

.help-collapse-text:focus-visible,
.help-header-text:focus-visible {
  outline: none !important;
}

.collapse-container {
  justify-content: space-between;
  padding: 0 1rem;
}

.help-collapse-text img {
  display: flex;
  width: 75%;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  .help-collapse-text img {
    width: 45%;
  }
}

.height-600px {
  height: 600px;
}

.delete-confirm {
  padding: 0.625rem 0.625rem 0 0;
}

.drawer-btn-container {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @mercury-color;
  padding: 0.625rem 1rem;
  background: @black-squeeze;
  text-align: left;
  font-weight: 600;
  display: flex;
}

.custom-html-style a {
  color: @primary-color !important;
}

.custom-html-style p {
margin: 0 !important;
    padding: 0 1rem 0 0 !important;
}

.custom-html-style > * {
  overflow: auto;
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;