@import "/ThemeVariables.less";

.sub-container {
  background: @off-catskill-white-color;
  border-radius: 0.5rem;
  padding: 0.5125rem;
}

.admin-tabs .ant-tabs-tab.ant-tabs-tab-active .tab-header {
  color: @primary-color !important;
}
.admin-tabs .ant-tabs-ink-bar {
  background: @primary-color !important;
}
.school-form {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
}

.search-school-container {
  width: 270px !important;
  height: 2.5rem !important;
}

.school-form-dropdown {
  width: 125px !important;
}

.tablecell .ant-table-thead > tr > th {
  background: @primary-color;
  height: 2.5rem;
}

.more-outline-style {
  color: @dust-gray-color;
  font-size: 1.5rem;
}

.admin-drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @mercury-color;
  padding: 0.625rem 1rem;
  background: @black-squeeze;
  text-align: left;
  font-weight: 600;
  display: flex;
}

.admin-drawer-divider {
  border-width: 0.375rem;
  border-color: @black-squeeze;
  width: 100%;
}

.admin-drawer-add-school-form {
  color: @light-dove-gray-color;
  font-size: 1rem;
  font-weight: 500;
}

.companysearchbtn .ant-col.ant-form-item-control {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.company-add-account {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}

.editcompany .ant-drawer-header {
  background: @off-catskill-white-color;
}

.editcompany .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}

.editcompany .ant-drawer-close {
  margin-right: 0px !important;
}

.login-data {
  .ant-input {
    height: 2.5rem;
    font-size: 1.125rem;
  }
  .ant-select-selector {
    height: 2.5rem;
  }
  .ant-select-selection-placeholder {
    font-size: 1.125rem;
  }
}

@primary-color: #ffc90a;@btn-primary-color: #000000;@btn-font-weight: 600;@border-radius-base: 8px;@link-color: #ffc90a;@font-size-base: 16px;@border-color-base: #bababa;@height-base: 40px;@checkbox-border-radius: 2px;@btn-disable-bg: #ebebe4;@btn-disable-border: #ebebe4;